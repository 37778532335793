import { MENUS } from '../constants';

export default function track({ name, content = '', current = 'home' }) {
  const link = MENUS.find((e) => e.id === current);
  const category = link ? link.name : '首页';

  // TODO 是否需要区分各类申请试用按钮？
  // function doTracking(event, targetName, targetId){
  //   try{
  //     clab_tracker.track(event, targetName, targetId, {
  //       c_title: document.title,
  //       c_URL: self.location.href
  //     }, function(){
  //       //callBack
  //     });
  //   }
  //   catch(e){
  //
  //   }
  // }
  // $(".header_trybutton").click(function(){
  //   doTracking("c_2018040302", "博客顶部申请试用按钮", "header_trybutton");
  //   applyform();
  // });
  //
  // $(".intro_trybutton").click(function(){
  //   doTracking("c_2018040301", "博客形象大图申请试用按钮", "intro_trybutton");
  //   applyform();
  // });
  //
  // $(".open_applyform").click(function(){
  //   doTracking("c_20180727", "博客固定右侧栏与页面详情申请试用按钮", "open_applyform");
  //   applyform();
  // });
  //
  // $(".footer_trybutton").click(function(){
  //   doTracking("c_2018040303", "博客底部申请试用按钮", "footer_trybutton");
  //   applyform();
  // });

  try {
    if (window.clab_tracker) {
      window.clab_tracker.track('c_click_official_website', {
        c_url: window.location.href,
        c_button_title: name,
        c_group: '荟聚官网',
        c_category: category || '首页',
        c_content: content,
      });
    }
  } catch (err) {
    console.log(err);
  }
}
