import React from 'react';

const ApplyDialogContext = React.createContext({
  visible: false,
  setVisible: () => {},
  href: '',
  setHref: () => {},
  modalType: '',
  setModalType: () => {},
  pageFrom: '',
  setPageFrom: () => {},
  fileId: '',
  setFileId: () => {},
  productId: '',
  setProductId: () => {},
});

export default ApplyDialogContext;
