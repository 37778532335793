import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ApplyDialogContext from '../context/applyDialog';
import track from '../utils/track';
import CurrentContext from '../context/current';

export default function ApplyButton({
  children,
  size = 'medium',
  fluid = false,
  phone = false,
  className,
  buttonPosition = 'menu',
  productId,
  ...restProps
}) {
  const { setVisible, setProductId } = useContext(ApplyDialogContext);
  const { current } = useContext(CurrentContext);

  let cName = 'apply-btn';
  if (size === 'large') {
    cName += ' apply-btn--large';
  }
  if (fluid) {
    cName += ' apply-btn--fluid';
  }
  if (phone) {
    cName += ' apply-btn--phone';
  }
  if (className) {
    cName += ` ${className}`;
  }
  let content = '顶部菜单';
  if (buttonPosition === 'middle') {
    content = '中间申请模块';
  } else if (buttonPosition === 'bottom') {
    content = '底部申请模块';
  }

  return (
    <span
      className={cName}
      onClick={() => {
        track({
          name: '申请试用',
          content,
          current,
        });
        setProductId(productId);
        setVisible(true);
      }}
      aria-hidden="true"
      {...restProps}
    >
      {children}
    </span>
  );
}

ApplyButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  fluid: PropTypes.bool,
  phone: PropTypes.bool,
  buttonPosition: PropTypes.oneOf(['menu', 'middle', 'bottom']),
  productId: PropTypes.string,
};
